import axiosInstance from "@/axios";

class CommonProvider {
  async obterPermissoesModulo(modulo) {
    return await axiosInstance
      .get(`api/Permissao/BuscarPermissoes?Modulo=${modulo}`)
      .then(response => response.data.Response.Acao);
  }
}

export default new CommonProvider();
