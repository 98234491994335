export default {
  data() {
    return {
      proposalStatusOptionsMixin: [
        { id: 1, name: "Cotação", typeId: 1, ProposalTimelineStatusId: null },
        {
          id: 2,
          name: "Em análise",
          typeId: 2,
          ProposalTimelineStatusId: null
        },
        {
          id: 3,
          name: "Aguardando Emissão (Corretora)",
          typeId: 3,
          ProposalTimelineStatusId: 3
        },
        {
          id: 34,
          name: "Aguardando Emissão (Seguradora)",
          typeId: 3,
          ProposalTimelineStatusId: 2
        },
        {
          id: 4,
          name: "Aguardando Pagamento de Boleto",
          typeId: 4,
          ProposalTimelineStatusId: null
        },
        { id: 5, name: "Emitida", typeId: 5, ProposalTimelineStatusId: null },
        {
          id: 6,
          name: "Aguardando corretora",
          typeId: 6,
          ProposalTimelineStatusId: null
        },
        {
          id: 98,
          name: "Recusada",
          typeId: 98,
          ProposalTimelineStatusId: null
        },
        {
          id: 99,
          name: "Cancelada",
          typeId: 99,
          ProposalTimelineStatusId: null
        }
      ],
      proposalStatusOptionsInsurerMixin: [
        {
          id: 2,
          typeId: 2,
          name: "Em análise",
          ProposalTimelineStatusId: null
        },
        {
          id: 3,
          typeId: 3,
          name: "Aguardando Emissão (Corretora)",
          ProposalTimelineStatusId: 3
        },
        {
          id: 4,
          typeId: 3,
          name: "Aguardando Emissão (Seguradora)",
          ProposalTimelineStatusId: 2
        },
        { id: 5, typeId: 5, name: "Emitida", ProposalTimelineStatusId: null },
        {
          id: 6,
          typeId: 6,
          name: "Aguardando corretora",
          ProposalTimelineStatusId: null
        },
        {
          id: 98,
          typeId: 98,
          name: "Recusada",
          ProposalTimelineStatusId: null
        },
        {
          id: 97,
          typeId: 97,
          name: "Rejeitada",
          ProposalTimelineStatusId: null
        },
        {
          id: 99,
          typeId: 99,
          name: "Cancelada",
          ProposalTimelineStatusId: null
        }
      ],
      policyStatusOptionsMixin: [
        { id: 1, name: "Aguardando Emissão" },
        { id: 2, name: "Emitida" },
        { id: 3, name: "Cancelada" },
        { id: 4, name: "Vencida" },
        { id: 5, name: "Rejeitada" }
      ],
      proposalTypeOptionsMixin: [
        { id: 1, name: "Proposta/Cotação" },
        { id: 2, name: "Proposta de Endosso" }
      ]
    };
  }
};
