<template>
  <section class="mt-4">
    <div class="new-history">
      <h2 class="font-bold">Histórico de consultas</h2>
      <template v-if="historico.length > 0">
        <vs-table
          :data="historico"
          :hoverFlat="true"
          noDataText="Nenhuma histórico encontrado"
        >
          <template slot="thead">
            <vs-th>Identificação da consulta</vs-th>
            <vs-th>Onde foi consultado?</vs-th>
            <vs-th class="text-left">Data da consulta</vs-th>
            <vs-th></vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
              <vs-td :data="data[indextr].Id" class="text-left">
                {{ item.Id || "-" }}
              </vs-td>

              <vs-td :data="item.Origin" class="text-left">
                {{ item.Origin }}
              </vs-td>

              <vs-td :data="item.CreateDate" class="text-left">
                {{ item.CreateDate | moment("DD/MM/YYYY HH:mm") }}
              </vs-td>

              <vs-td class="text-right">
                <vs-dropdown
                  vs-custom-content
                  vs-trigger-click
                  class="btn-action-apolice"
                >
                  <vs-button color="dark" type="flat" title="Ações">
                    <i class="onpoint-dots-three-vertical icon-dots-three-vertical" role="button"></i>
                  </vs-button>
                  <vs-dropdown-menu class="btn-action-apolice-options">
                    <vs-dropdown-item>
                      <p
                        class="text-base text-nowrap"
                        @click="openModalJson(item)"
                      >
                        <span>Visualizar JSON</span>
                      </p>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <vs-pagination
          class="mt-5"
          :total="numberOfPages"
          v-model="currentPage"
          v-if="numberOfPages > 1"
        />
      </template>

      <template v-else>
        <div class="flex flex-col w-full md:w-1/2 mx-auto items-center mt-32">
          <i class="onpoint-file-text"/>
          <p class="text-center">
            Nenhum registro encontrado!
          </p>
        </div>
      </template>
    </div>

    <vs-popup
      id="listar-endosso-modal"
      title=" "
      :active.sync="showRequestJSON"
    >
      <div class="vw-row mt-3">
        <div class="vx-col w-full text-right">
          <vs-button @click="downloadJson()" class="button-primary">
            Copiar JSON
          </vs-button>
        </div>

        <div class="vx-col pt-5 w-full mt-2">
          <tree-view
            id="element-to-print"
            :data="(requestJson || {}).Content"
            :options="{ maxDepth: 3, rootObjectKey: 'JSON' }"
          ></tree-view>
        </div>
      </div>
    </vs-popup>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import optionsStaticMixin from "../../mixins/optionsStaticMixin";
import html2pdf from "html2pdf.js";

export default {
  name: "apolices-segurado",
  props: { document: { type: String, require: true } },
  mixins: [optionsStaticMixin],
  mounted() {
    this.listarHitoricoConsultasSegurado();
  },
  data() {
    return {
      historico: [],
      requestJson: null,
      showRequestJSON: false,
      currentPage: 1,
      rowsPerPage: 7,
      numberOfPages: 0
    };
  },
  watch: {
    currentPage(newVal, oldVal) {
      if (newVal != oldVal) this.listarHitoricoConsultasSegurado();
    }
  },
  methods: {
    ...mapActions("pessoa-module", ["getPersonThr3dAPIHistory"]),

    async listarHitoricoConsultasSegurado() {
      await this.$onpoint.loading(async() => {
        return await this.getPersonThr3dAPIHistory({
          document: this.document,
          currentPage: this.currentPage,
          rowsPerPage: this.rowsPerPage
        })
          .then(response => {
            this.historico = response.Data;
            this.numberOfPages = response.NumberOfPages;
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
          });
      });
    },

    openModalJson(content) {
      this.showRequestJSON = true;
      this.requestJson = content;
    },

    downloadJson() {
      var element = document.getElementById("element-to-print");
      html2pdf(element);
    }
  }
};
</script>

<style lang="scss" scoped>
.table-list {
  ::v-deep .vs-table--thead {
    tr {
      background: transparent !important;
    }
  }

  ::v-deep .vs-table--td {
    padding: 31px 15px !important;
    margin-bottom: 1em;
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }
}

.text-nowrap {
  white-space: nowrap;
}
</style>

<style lang="scss">
.tree-view-item-key-with-chevron {
  color: red;
}

.tree-view-item-key-with-chevron[data-v-efc5bae2]::before {
  content: "\f067" !important;
  font-family: FontAwesome;
}

.tree-view-item-key-with-chevron.opened[data-v-efc5bae2]::before {
  content: "\f068" !important;
  transform: none;
  font-family: FontAwesome;
}

.icon-dots-three-vertical::before{
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #afafaf;
}
</style>
